$(function () {
  // transparent header
  var prevScrollpos = window.pageYOffset;
  /* Get the header element and it's position */
  var headerDiv = document.querySelector("header");

  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos <= currentScrollPos) {
      headerDiv.classList.remove("min-header");
      if (currentScrollPos > headerDiv.offsetHeight) {
        headerDiv.style.top = "-7.2rem";
        $('.header-menu-dropdown').slideUp(100);
      } else {
        headerDiv.style.top = "0";
      }
    }
    else {
      headerDiv.classList.add("min-header");
      headerDiv.style.top = "0";
      if (currentScrollPos <= headerDiv.offsetHeight) {
        headerDiv.classList.remove("min-header");

      }
    }
    prevScrollpos = currentScrollPos;
  }

  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu-outer').toggleClass('active');
  });

  $('.menu-link').click(function (e) {
    if ($(window).width() <= 900) {
      if ($(this).hasClass('mobile-cate-show')) {
        e.preventDefault();
      }
      if ($(this).parent('.header-menu-link').hasClass('is-active')) {
        $(this).siblings('.header-menu-dropdown').slideUp(300);
        setTimeout(() => {
          $(this).parent('.header-menu-link').removeClass('is-active');
        }, 300);
      } else {
        $(this).parent('.header-menu-link').addClass('is-active');
        $(this).siblings('.header-menu-dropdown').slideDown(300);
      }
    }
  });
  $(".header-menu-link").hover(function () {
    if ($(window).width() > 900) {
      var isHovered = $(this).is(":hover");
      if (isHovered) {
        $(this).children('.header-menu-dropdown').stop().slideDown(300);
      } else {
        $(this).children('.header-menu-dropdown').stop().slideUp(300);
      }
    }
  });

  $(window).resize(function () {
    if ($(window).width() > 900) {
      $('.header-menu-dropdown').slideUp(300);
      $('.header-menu-link').removeClass('is-active');
    }
  });

  var headerDropdownCate = $('.header-menu-dropdown .dropdown-cate');

  headerDropdownCate.on('mouseenter', function () {
    var cateID = $(this).data('id');
    var cateContent = $('.dropdown-cate-items[data-content=' + cateID + ']');
    cateContent.addClass('is-active').siblings().removeClass('is-active');
    var itemImg = cateContent.find('.dropdown-cate-item').first().data('img');
    $(this).addClass('is-active').siblings().removeClass('is-active');
    $(this).parent().siblings('.dropdown-cate-item-img').find('img').attr("src", itemImg);
  });

  $('.header-menu-dropdown').each(function () {
    $(this).find('.dropdown-cate').first().trigger('mouseenter');
  });

  var headerDropdownCateItem = $('.header-menu-dropdown .dropdown-cate-item');
  headerDropdownCateItem.hover(function () {
    let itemImg = $(this).data('img');
    if (itemImg == '') {
      $(this).parents('.dropdown-cate-content').siblings('.dropdown-cate-item-img').find('img').attr("src", "/images/default_image.png");
    } else {
      $(this).parents('.dropdown-cate-content').siblings('.dropdown-cate-item-img').find('img').attr("src", itemImg);
    }
  });
});
