$(document).ready(function () {
  if ($('.wrap').data('page') == 'home') {

    let mm = gsap.matchMedia();
    mm.add("(orientation: portrait)", () => {
      let indexMain_tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".index-vision-about",
          start: "top bottom",
          end: "8% center",
          scrub: 0.2,
          // markers: true,
        }
      });
      indexMain_tl
        .fromTo("#vision_star", { xPercent: -1, yPercent: -30, scale: 0, opacity: 0 }, { xPercent: 0, yPercent: 0, scale: 1, opacity: 1 })
        .to(".vision-main-content", { yPercent: 50 }, 0)
        .to("#vision_star", { xPercent: 0, scale: 0, opacity: 0 })


      let indexAbout_tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".index-vision-about",
          start: "8% center",
          end: "bottom center",
          scrub: 0.3,
          // markers: true,
        }
      });
      indexAbout_tl
        .from(".vision-about-light-orange", { opacity: 0 }, 0)
        .to("#vision_star", { xPercent: -20, yPercent: -10, scale: 0, opacity: 0 })
        .to("#vision_star", { xPercent: -5, yPercent: 0, scale: 1, opacity: 1 })
        .from("#v_about_1", { xPercent: -100, opacity: 0 }, '<-=0')
        .to("#vision_star", { xPercent: 5, scale: 0.8, opacity: 1 })
        .from("#v_about_2", { xPercent: 100, opacity: 0 }, '<-=0')
        .to("#vision_star", { xPercent: 0, scale: 1, opacity: 1 })
        .to("#vision_star", { xPercent: -2, scale: 0.8, opacity: 1 })
        .from("#v_about_3", { xPercent: -100, opacity: 0 }, '<-=0')
        .to("#vision_star", { xPercent: 0, scale: 1, opacity: 1 })
        .to("#vision_star", { xPercent: -2, scale: 1, opacity: 1 })
        .from("#v_about_4", { opacity: 0 }, '<-=0')
        .to("#vision_star", { xPercent: 0, scale: 0.8, opacity: 0.6 })
        .to("#vision_star", { xPercent: 0, scale: 0.5, opacity: 0 })
        .from(".vision-spark", { xPercent: 300, yPercent: -380, opacity: 0, stagger: 0.1, duration: 1 }, 0)
        .to(".vision-spark", { xPercent: -600, yPercent: 1000, opacity: 0, scale: 0.3, stagger: 0.1, duration: 4 }, 1)
        .from(".vision-spark-r", { xPercent: -100, yPercent: -180, opacity: 0, stagger: 0.1, duration: 2 }, 0)
        .to(".vision-spark-r", { xPercent: 550, yPercent: 600, opacity: 0, scale: 0.35, stagger: 0.1, duration: 4 }, 1.5)
        .to("#vision_star", { xPercent: 0, scale: 1, opacity: 1 })
    });

    mm.add("(orientation: landscape)", () => {
      let indexMain_tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".index-vision-about",
          start: "top bottom",
          end: "10% center",
          scrub: 0.2,
          // markers: true,
        }
      });
      indexMain_tl
        .to(".vision-main-content", { yPercent: 50 }, 0)
        .to(".vision-sky", { yPercent: 5 }, '<-=0')
        .fromTo("#vision_star", { xPercent: -1, yPercent: -17, scale: 0, opacity: 0 }, { xPercent: 0, yPercent: 0, scale: 1, opacity: 1 }, '<-=0')
        .to("#vision_star", { scale: 0, opacity: 0 })

      let indexAbout_tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".index-vision-about",
          start: "10% center",
          end: "bottom center",
        scrub: 0.2,
          // markers: true,
        }
      });
      indexAbout_tl
        .to("#vision_star", { xPercent: -14, scale: 0.5, opacity: 0 })
        .to("#vision_star", { xPercent: -16, scale: 1, opacity: 1 })
        .from("#v_about_1", { xPercent: -100, opacity: 0}, '<-=0')
        .to("#vision_star", { xPercent: -12, scale: 1, opacity: 0.8 })
        .to("#vision_star", { xPercent: -8, scale: 1, opacity: 1 })
        .from("#v_about_2", { xPercent: 100, opacity: 0}, '<-=0')
        .to("#vision_star", { xPercent: 3, scale: 1, opacity: 1 })
        .to("#vision_star", { xPercent: 5, scale: 0.5, opacity: 1 })
        .to("#vision_star", { xPercent: 3, scale: 1, opacity: 1 })
        .from("#v_about_3", { xPercent: -100, opacity: 0}, '<-=0')
        .to("#vision_star", { xPercent: 0, scale: 1, opacity: 1 })
        .to("#vision_star", { xPercent: -5, scale: 1, opacity: 1 })
        .from("#v_about_4", { xPercent: 100, opacity: 0}, '<-=0')
        .to("#vision_star", { xPercent: 0, scale: 1, opacity: 1 })
        .from(".vision-about-light-orange", { opacity: 0, duration: 3 }, 0)
        .from(".vision-spark", { xPercent: 100, yPercent: -180, opacity: 0, stagger: 0.1, duration: 2 }, 0)
        .to(".vision-spark", { xPercent: -600, yPercent: 900, opacity: 0, scale: 0.35, stagger: 0.1, duration: 3 }, 1.5)
        .from(".vision-spark-r", { xPercent: -100, yPercent: -180, opacity: 0, stagger: 0.1, duration: 2 }, 0)
        .to(".vision-spark-r", { xPercent: 600, yPercent: 600, opacity: 0, scale: 0.35, stagger: 0.1, duration: 3 }, 1.5)
    });
    mm.add("all", () => {
      var Video = document.getElementById("vision_final_video");
      ScrollTrigger.create({
        trigger: ".vision-final-video",
        start: "center 50%",
        onEnter: () => {
          Video.play();
        },
      });
      ScrollTrigger.create({
        trigger: ".vision-final-video",
        start: "top bottom",
        onLeaveBack: () => {
          Video.load();
        },
      });

      let indexVideo_tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".vision-final-video",
          start: "center 56%",
          end: "center 47%",
          scrub: 0.3,
          // markers: true,
        }
      });
      indexVideo_tl
        .to("#vision_star", { xPercent: 0, yPercent: 0, scale: 1, opacity: 1 })
        .to("#vision_star", { xPercent: 0, yPercent: 0, scale: 0, opacity: 0 })
      });

    gsap.to("#leaf_1", {
      scrollTrigger: {
        trigger: ".index-vision-about",
        start: "center 60%",
        end: "bottom center",
        scrub: 1,
      },
      motionPath: {
        path: "#leaf_1_path",
        align: "#leaf_1_path",
        autoRotate: true,
        alignOrigin: [0.5, 0.5]
      }
    });
    gsap.to("#leaf_2", {
      scrollTrigger: {
        trigger: ".index-vision-about",
        start: "center 80%",
        end: "bottom center",
        scrub: 1,
      },
      ease: "power1.inOut",
      motionPath: {
        path: "#leaf_2_path",
        align: "#leaf_2_path",
        autoRotate: true,
        alignOrigin: [0.5, 0.5]
      }
    });
    gsap.to("#leaf_3", {
      scrollTrigger: {
        trigger: ".index-vision-about",
        start: "center 60%",
        end: "bottom 40%",
        scrub: 1,
      },
      motionPath: {
        path: "#leaf_3_path",
        align: "#leaf_3_path",
        autoRotate: true,
        alignOrigin: [0, 0.2]
      }
    });
    gsap.to("#leaf_4", {
      scrollTrigger: {
        trigger: ".index-vision-about",
        start: "center 55%",
        end: "bottom center",
        scrub: 1,
      },
      motionPath: {
        path: "#leaf_4_path",
        align: "#leaf_4_path",
        autoRotate: true,
        alignOrigin: [0.5, 0.5]
      }
    });
    gsap.to("#leaf_5", {
      scrollTrigger: {
        trigger: ".index-vision-about",
        start: "center 50%",
        end: "bottom center",
        scrub: 1,
      },
      motionPath: {
        path: "#leaf_5_path",
        align: "#leaf_5_path",
        autoRotate: true,
        alignOrigin: [0, 0.2]
      }
    });
  };


  // banner
  var indexBanner = new Swiper('#indexBanner', {
    effect: 'fade',
    autoplay: {
      delay: 5000,
    },
    loop: true,
    peed: 1000,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
  });

  //product
  var indexProducts = new Swiper('#indexProducts', {
    slidesPerView: 4,
    peed: 1000,
    spaceBetween: 30,
    freeMode: true,
    breakpoints: {
      0: {
        slidesPerView: 1.7,
        spaceBetween: 15,
        slidesOffsetBefore: 25,
        slidesOffsetAfter: 25,
      },
      640: {
        slidesPerView: 3,
      },
      900: {
        slidesPerView: 4,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
  });
});
