$(document).ready(function () {
  if ($('.wrap').data('page') == 'about') {

    const aboutAn = gsap.timeline();
    aboutAn
      .from(".show-in", { y: 20, opacity: 0, stagger: 0.2, duration: 0.7 })


    gsap.to(".about-spark-content-l .about-spark.spark-1", {
      motionPath: {
        path: [{ x: 0, y: 0, opacity: 0 }, { x: 0, y: 0, opacity: 1 }, { x: 80, y: 180, opacity: 1 }, { x: 200, y: 200, opacity: 1 }, { x: 180, y: 400, opacity: 1 }, { x: 300, y: 600, opacity: 1 }, { x: 430, y: 700, opacity: 0 }],
      },
      stagger: 0.25,
      duration: 8.5,
      ease: "none",
      repeat: -1,
      repeatDelay: 3
    });

    gsap.to(".about-spark-content-l .about-spark.spark-2", {
      motionPath: {
        path: [{ x: 0, y: 0, opacity: 0 }, { x: 0, y: 0, opacity: 1 }, { x: 70, y: 100, opacity: 1 }, { x: 200, y: 200, opacity: 1 }, { x: 120, y: 320, opacity: 1 }, { x: 200, y: 450, opacity: 1 }, { x: 300, y: 550, opacity: 1 }, { x: 360, y: 650, opacity: 0 }],
      },
      stagger: 0.15,
      duration: 8,
      ease: "none",
      repeat: -1,
      repeatDelay: 3.8
    });

    gsap.to(".about-spark-content-r .about-spark", {
      motionPath: {
        path: [{ x: 0, y: 0, opacity: 0 }, { x: 0, y: 0, opacity: 1 }, { x: -200, y: 100, opacity: 1 }, { x: -160, y: 220, opacity: 1 }, { x: -250, y: 400, opacity: 1 }, { x: -300, y: 600, opacity: 1 }, { x: -430, y: 680, opacity: 1 }, { x: -380, y: 720, opacity: 1 }, { x: -500, y: 800, opacity: 0 }],
      },
      stagger: 0.3,
      duration: 7.5,
      ease: "none",
      repeat: -1,
      repeatDelay: 2.5
    });
  };

});
