import scrollSpy from 'simple-scrollspy';
$(document).ready(function () {
  // banner
  var collectionBanner = new Swiper('#collectionBanner', {
    effect: 'fade',
    autoplay: {
      delay: 5000,
    },
    loop: true,
    peed: 1000,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
  });

  //product
  var newProducts = new Swiper('#newProducts', {
    slidesPerView: 4,
    peed: 1000,
    spaceBetween: 30,
    freeMode: true,
    breakpoints: {
      0: {
        slidesPerView: 1.9,
        spaceBetween: 15,
        slidesOffsetBefore: 25,
        slidesOffsetAfter: 25,
      },
      640: {
        slidesPerView: 3,
      },
      900: {
        slidesPerView: 4,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
  });
  var featuredProducts = new Swiper('#featuredProducts', {
    slidesPerView: 4,
    peed: 1000,
    spaceBetween: 30,
    freeMode: true,
    breakpoints: {
      0: {
        slidesPerView: 1.9,
        spaceBetween: 15,
        slidesOffsetBefore: 25,
        slidesOffsetAfter: 25,
      },
      640: {
        slidesPerView: 3,
      },
      900: {
        slidesPerView: 4,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
  });
  var bestsellingProducts = new Swiper('#bestsellingProducts', {
    slidesPerView: 4,
    peed: 1000,
    spaceBetween: 30,
    freeMode: true,
    breakpoints: {
      0: {
        slidesPerView: 1.9,
        spaceBetween: 15,
        slidesOffsetBefore: 25,
        slidesOffsetAfter: 25,
      },
      640: {
        slidesPerView: 3,
      },
      900: {
        slidesPerView: 4,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
  });

  var products = new Swiper('#products', {
    peed: 1000,
    spaceBetween: 2,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
  });

  const options = {
    sectionClass: '.scrollspy',
    menuActiveTarget: '.sidebar-item',
    hrefAttribute: 'data-menu',
    smoothScroll: true,
    offset: -100,
  };
  if ($('#product_sidebar').length > 0) {
    scrollSpy('#product_sidebar', options);
  }
});
