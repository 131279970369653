// import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs } from 'swiper';


Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  //scroll top
  $('.back-to-top').click(function(event){
    $("html,body").animate({scrollTop: 0}, 1000);
  });

  // article image change
  var articleItem = $('.full-article-area').find('.article-item')
  articleItem.hover(function () {
    let itemImg = $(this).data('img');
    if (itemImg != '') {
      $(this).parents('.full-article-content').siblings('.full-article-img').find('picture source').attr("srcset", itemImg);
    }
  });

  // table responsive
  if ($('.custom-editor table').length >= 1) {
    $('.custom-editor table').wrap('<div class="table-responsive"/></div>');
  }
  // custom-editor iframe responsive
  if ($('.custom-editor iframe').length >= 1) {
    $('.custom-editor iframe').wrap('<div class="iframe-outer"></div>');
  }

  // tab
  $('.tab[data-tab]').on('click', function () {
    var tabPanes = $(this).data('tabs');
    var ID = $(this).data('tab');
    $(this).addClass('is-active').siblings().removeClass('is-active');
    $("[data-tab-panes=" + tabPanes + "]").find("[data-tab-pane=" + ID + "]").addClass('is-active').siblings().removeClass('is-active');
  });

  if ($('.banner-default-imgs').length >= 1) {
    gsap.to("#banner_star", {
      duration: 5,
      ease: "power1.inOut",
      repeat: -1,
      repeatDelay: 3,
      motionPath: {
        path: "#banner_star_path",
        align: "#banner_star_path",
        autoRotate: true,
        alignOrigin: [0.5, 0.5],
      }
    });
  }

  if ($('.banner-img-words').length >= 1) {
    let default_banner = gsap.timeline();
    default_banner
      .from(".banner-img-words .text", { opacity: 0, duration: 1.2, xPercent: -100, stagger: 0.3, ease: "Expo.easeOut" })
  }

  //customerTestimonial
  var customerTestimonial = new Swiper('#customerTestimonial', {
    slidesPerView: 5,
    autoplay: true,
    loop: true,
    peed: 2000,
    freeMode: true,
    breakpoints: {
      0: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
      1600: {
        slidesPerView: 5,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

});
