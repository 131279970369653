import Cookies from 'js-cookie';
import 'core-js';
import 'public/main'
import 'public/style.scss'
import Lenis from '@studio-freight/lenis';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
// import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import { MotionPathPlugin } from 'gsap/dist/MotionPathPlugin';
import 'public/header'
import 'public/home'
import 'public/collections'
import 'public/about'
import ahoy from "ahoy.js"
import Rails from "@rails/ujs"

Rails.start();
gsap.registerPlugin(
  ScrollTrigger,
  // ScrollToPlugin,
  MotionPathPlugin,
);

window.jQuery = $;
window.$ = $;
window.Cookies = Cookies;
window.gsap = gsap;
window.ScrollTrigger = ScrollTrigger;
window.MotionPathPlugin = MotionPathPlugin;
window.Lenis = Lenis;

const lenis = new Lenis({
  duration: 1.2,
  easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t))
});

function raf(time) {
  lenis.raf(time);
  ScrollTrigger.update();
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);
